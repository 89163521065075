<template>
	<div class="case_item" @click="toDeatail">
		<img :src="info.image" class="case_img" alt="" />
		<div class="case_content flexcol">
			<div class="case_time">
				{{info.stime||''}}
			</div>
			<div class="case_tit">
				{{info.name}}
			</div>
			<div class="case_t">
				{{info.content}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			info:{
				type:Object,
				default:()=>{
					return {}
				}
			}
		},
		data() {
			return {
				baseUrl: this.$BaseUrl,
			}
		},
		methods:{
			toDeatail(){
				// this.$emit('toDeatail',this.info)
				this.$router.push({
					path:"/khxq",
					query:{
						id:this.info.id
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.case_time {
		background-image: url("../assets/case_bg.png");
		background-size: 100%;
		width: 8rem;
		height: 2rem;
		position: absolute;
		left: 0;
		top: 1rem;
		font-size: 0.875rem;
		font-weight: 600;
		color: #F40009;
		line-height: 2rem;
		padding-left: 1.5rem;
		box-sizing: border-box;
	}
	.case_item {
		cursor: pointer;
		width: 19.25rem;
		margin-top:  3rem;
		margin-right: 1rem;

		// font-size: 0;
		&:nth-of-type(4n) {
			margin-right: 0;
		}

		&:hover {
			.case_content {
				background: #F40009;
				color: #FFFFFF;
			}

			.case_time {
				color: #FFFFFF;
				background-image: url("../assets/case_bg_act.png");
			}
		}
	}

	.case_content {
		width: 19.25rem;
		height: 18.5rem;
		background: #FFFFFF;
		// border: 1px solid #FFFFFF;
		padding-top: 4.5rem;
		box-sizing: border-box;
		position: relative;
	}

	.case_img {
		width: 19.25rem;
		height: 12.5rem;
		color: #16202B;
		vertical-align: top;
	}

	.case_tit {
		width: 16rem;
		font-size: 1.25rem;
		font-weight: 600;
		line-height: 1.75rem;
	}

	.case_t {
		width: 16rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		margin-top: 1rem;
	}
</style>