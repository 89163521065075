import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/',name: 'home',component: HomeView},
  // 展商指南
  {path: '/zszn',name: 'zszn',component: () => import('../views/exhibit/ZsznView.vue')},
  // 我要参展
  {path: '/wycz',name: 'wycz',component: () => import('../views/exhibit/WyczView.vue')},
  // 购票详情
  {path: '/gpxq',name: 'gpxq',component: () => import('../views/exhibit/GpxqView.vue')},
  // 活动专区
  {path: '/hdzq',name: 'hdzq',component: () => import('../views/exhibit/HdzqView.vue')},
  // 展商名录
  {path: '/zsml',name: 'zsml',component: () => import('../views/exhibit/ZsmlView.vue')},
  // 历届展览
  {path: '/ljzl',name: 'ljzl',component: () => import('../views/exhibit/LjzlView.vue')},
  // 往届论坛
  {path: '/wjlt',name: 'wjlt',component: () => import('../views/luntan/WjltView.vue')},
  // 往届嘉宾
  {path: '/wjjb',name: 'wjjb',component: () => import('../views/luntan/WjjbView.vue')},
  // 图文资讯
  {path: '/twzx',name: 'twzx',component: () => import('../views/zixun/TwzxView.vue')},
  // 视频资讯
  {path: '/spzx',name: 'spzx',component: () => import('../views/zixun/SpzxView.vue')},
  // 直播回访
  {path: '/zbhf',name: 'zbhf',component: () => import('../views/zixun/ZbhfView.vue')},
  // 赛事
  {path: '/ss',name: 'ss',component: () => import('../views/SsView.vue')},
  // 研习社
  {path: '/yxs',name: 'yxs',component: () => import('../views/funeng/YxsView.vue')},
  // 新经略
  {path: '/xjl',name: 'xjl',component: () => import('../views/funeng/XjlView.vue')},
  // 客户案例
  {path: '/khal',name: 'khal',component: () => import('../views/cases/KhalView.vue')},
  // 客户案例详情
  {path: '/khxq',name: 'khxq',component: () => import('../views/cases/KhxqView.vue')},
  // 图文详情
  {path: '/detail',name: 'detail',component: () => import('../views/DetailView.vue')},
  // 赞助商名录
  {path: '/zzsml',name: 'zzsml',component: () => import('../views/gywm/ZzsmlView.vue')},
  // 联系我们
  {path: '/lxwm',name: 'lxwm',component: () => import('../views/gywm/LxwmView.vue')},
  // 关于我们
  {path: '/gywm',name: 'gywm',component: () => import('../views/gywm/GywmView.vue')},
  // 供需平台
  {path: '/gxpt',name: 'gxpt',component: () => import('../views/gxpt/GxptView.vue')},
  // 我的页面
  {path: '/wdym',name: 'wdym',component: () => import('../views/gxpt/WdymView.vue')},
  // 内容详情
  {path: '/nrxq',name: 'nrxq',component: () => import('../views/gxpt/NrxqView.vue')},
  // 发布需求
  {path: '/fbxq',name: 'fbxq',component: () => import('../views/gxpt/FbxqView.vue')},
  // 登录
  {path: '/login',name: 'login',component: () => import('../views/mobileLogin.vue')},
  // 邀请函
  {path: '/yqh',name: 'yqh',component: () => import('../views/YqhView.vue')},
  // 逛展指南
  {path: '/gzzn',name: 'gzzn',component: () => import('../views/GzznView.vue')},
  // 链接内容
  {path: '/ljnr',name: 'ljnr',component: () => import('../views/LjnrView.vue')},
  // 编辑邀请函
  {path: '/cusyqh',name: 'cusyqh',component: () => import('../views/CusyqhView.vue')},
  {path: '/about',name: 'about',component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
