import ServiceApi from "./service";
let Service = ServiceApi.service;
let Service1 = ServiceApi.service1;
// 所有的接口都要定义在该文件
export default {
	// 首页banner
	banner(params) {
		return Service.post("/home/banner", params);
	},
	// 首页品牌方
	brand(params) {
		return Service.get("/home/brand", params);
	},
	// 首页产品与服务 produnct
	produnct(params) {
		return Service.get("/home/produnct", params);
	},
	// 饮品赛道 drinksai
	drinksai(params) {
		return Service.get("/home/drinksai", params);
	},
	// 往届嘉宾 alongguess
	alongguess(params) {
		return Service.get("/home/alongguess", params);
	},
	// 嘉宾评价 guesspj
	guesspj(params) {
		return Service.get("/home/guesspj", params);
	},
	// 视频咨询 video
	video(params) {
		return Service.get("/home/video", params);
	},
	// 近期展会与论坛 exhibition
	exhibition(params) {
		return Service.get("/home/exhibition", params);
	},
	// 客户案例 gucase
	gucase(params) {
		return Service.get("/home/gucase", params);
	},
	// 客户案例详情 gucasedeyail
	gucasedeyail(params) {
		return Service.post("/home/gucasedeyail", params);
	},
	// 展览内容 product/prdPct
	productPrdouct(params) {
		return Service.post("/product/prdouct", params);
	},
	// 论坛分类 ltcatrhgory
	ltcatrhgory(params) {
		return Service.post("/product/ltcatrhgory", params);
	},
	// 论坛 tallk
	tallk(params) {
		return Service.post("/product/tallk", params);
	},
	// 往届嘉宾 alongue
	alongue(params) {
		return Service.post("/product/alongue", params);
	},
	// 图文/视频/直播 资讯分类articcategory
	articcategory(params) {
		return Service.post("/product/articcategory", params);
	},
	// 图文资讯 picture
	picture(params) {
		return Service.post("/product/picture", params);
	},
	// 视频资讯 video
	productVideo(params) {
		return Service.post("/product/video", params);
	},
	// 供需平台 /supply/supplylist
	supplylist(params) {
		return Service.post("/supply/supplylist", params);
	},
	// 热门信息 hotsupply
	hotsupply(params) {
		return Service.post("/supply/hotsupply", params);
	},
	// 赛事/新经略/研习社 game
	game(params) {
		return Service.post("/product/game", params);
	},
	// 赛事详情 gamedetail
	gamedetail(params) {
		return Service.post("/product/gamedetail", params);
	},
	// 发送短信 sendsms
	sendsms(params) {
		return Service.post("/product/sendsms", params);
	},
	// 登录 mobilelogin
	mobilelogin(params) {
		return Service.post("/user/mobilelogin", params);
	},
	// 完善信息 changeall
	changeall(params) {
		return Service.post("/user/changeall", params);
	},
	// 供需话题 supplycategory
	supplycategory(params) {
		return Service.post("/supply/supplycategory", params);
	},
	// 个人信息 userinfo
	userinfo(params) {
		return Service.post("/supply/userinfo", params);
	},
	// 供需详情
	supplydetail(params) {
		return Service.post("/supply/supplydetail", params);
	},
	// 收藏 pommern
	userfollow(params) {
		return Service.post("/supply/userfollow", params);
	},
	// 发表评论 pommern
	pommern(params) {
		return Service.post("/supply/pommern", params);
	},
	// 回复评论 pommernhf
	pommernhf(params) {
		return Service.post("/supply/pommernhf", params);
	},
	// 赞助商名录 side
	side(params) {
		return Service.post("/product/side", params);
	},
	// 联系我们aboutcall
	aboutcall(params) {
		return Service.post("/product/aboutcall", params);
	},
	// 关于我们 neir
	neir(params) {
		return Service.post("/product/neir", params);
	},
	// 图文资讯详情 picturedetail
	picturedetail(params) {
		return Service.post("/product/picturedetail", params);
	},
	// 视频点赞 videozan
	videozan(params) {
		return Service.post("/product/videozan", params);
	},
	// 供需平台搜索 likecontent
	likecontent(params) {
		return Service.post("/supply/likecontent", params);
	},
	// 上传照片 addpostspic
	addpostspic(params) {
		return Service.post("/home/uploads", params);
	},
	// 发布供需 relesupply
	relesupply(params) {
		return Service.post("/supply/relesupply", params);
	},
	// 产品品类 supplyproduct
	supplyproduct(params) {
		return Service.post("/supply/supplyproduct", params);
	},
	// 历届展览选择 exh
	exh(params) {
		return Service.post("/product/exh", params);
	},
	// 我的发布 mycontent
	mycontent(params) {
		return Service.post("/supply/mycontent", params);
	},
	// 取出草稿 outdraft
	outdraft(params) {
		return Service.post("/supply/outdraft", params);
	},
	// 直播相关推荐 aboutzb
	aboutzb(params) {
		return Service.post("/product/aboutzb", params);
	},
	// 直播详情 livevideo
	livevideo(params) {
		return Service.post("/product/livevideo", params);
	},
	// 活动列表 /weapp/main/huodong/list
	huodong(params) {
		return Service1.post("/pin/api/weapp/huodong/getList", params);
	},
	// 获取二维码 /detail/getQrcode
	getQrcode(params) {
		return Service1.post("/pin/api/weapp/huodong/detail/getQrcode", params);
	},
	// 活动详情 /pin/api/weapp/huodong/detail/load
	load(params) {
		return Service1.post("/pin/api/weapp/huodong/detail/load", params);
	},
	// 邀请函 /pin/api/weapp/huodong/yqh
	yqh(params) {
		return Service1.post("/pin/api/weapp/huodong/yqh", params);
	},
	// 逛展指南 /pin/api/weapp/huodong/gzzn
	gzzn(params) {
		return Service1.post("/pin/api/weapp/huodong/gzzn", params);
	},
	// 链接内容 /pin/api/weapp/huodong/gzzn
	ljnr(params) {
		return Service1.post("/pin/api/weapp/huodong/ljnr", params);
	},
	// 邀请函列表
	yqhList(params) {
		return Service1.post("/pin/api/weapp/huodong/yqhList", params);
	},
	// 确定邀请函
	savecus(params) {
		return Service1.post("/pin/api/weapp/huodong/savecus", params);
	},
	// 微信签名 resgin
	resgin(params) {
		return Service.post("/home/resign", params);
	},
};
// 验证码
// captcha(params) {
// 	return Service.get("/api/user/captcha", params);
// }