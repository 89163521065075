<template>
	<div class="home">
		<HomeMain />
	</div>
</template>

<script>
	import HomeMain from "../components/HomeMain.vue"
	export default {
		name: 'HomeView',
		components: {
			HomeMain
		}
	}
</script>

<style lang="less">

</style>