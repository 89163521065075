<template>
	<div>
		<template>
			<div v-if="ismobile">
				<mobileHome @toPage="toPage" @toZh="toZh" :numList="numList" :banner="banner" :product="product" :drinksai="drinksai"
					:alongguess="alongguess" :guesspj="guesspj" :video="video" :exhibition='exhibition'
					:gucase="gucase" :menuList="menuList"  />
			</div>
			<div v-else class="flexcol">
				<!-- 轮播 -->
				<div style="width: 100%;">
					<el-carousel height="45rem">
						<el-carousel-item v-for="(item,index) in banner" :key="index">
							<img :src="item.image" class="banner_img" alt="" />
						</el-carousel-item>
					</el-carousel>
				</div>
				<!-- 菜单 -->
				<div class="menu" ref="numberTop">
					<div class="menu_item flexcol" @click="toPage(item)" v-for="(item,index) in menuList" :key="index">
						<p>{{item.eng}}</p>
						<p>{{item.chs}}</p>
						<div>进一步了解<img src="@/assets/arrow_red.png" alt="" /></div>
					</div>
				</div>
				<!-- 介绍 -->
				<div class="jieshao flexcol">
					<div class="jie">
						深度聚焦饮品行业的<span style="color: #F40009;">链接服务平台</span>
					</div>
					<p class="shao">
						专注饮品行业链接服务赋能：品牌方、经销商、产业链
					</p>
					<!-- 数字滚动 -->
					<div class="num_list">
						<div class="num_item flexcol" v-for="(item,index) in numList" :key="index">
							<template>
								<div class="num" v-if="item.nums<=item.currentNumber">
									{{item.num}}
								</div>
								<div class="num" v-else>
									<div v-for="(nums,index) in item.numbers" :key="index">
										{{nums}}
									</div>
								</div>
							</template>
							<div class="jis">{{item.jieshao}}</div>
						</div>
					</div>
					<!-- 长图滚动 -->
					<div class="box">
						<div class="imgList">
							<!-- <img v-for="(item,index) in images" :key="index" :src="item" alt="" /> -->
							<img src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705374333682.jpg" alt="" />
							<img src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705374360002.jpg" alt="" />
							<img src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705374333682.jpg" alt="" />
							<img src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705374360002.jpg" alt="" />
						</div>
					</div>
				</div>
				<!-- 品饮汇产品与服务 -->
				<div class="service flexcol">
					<p class="title">品饮汇产品与服务</p>
					<div class="service_main">
						<img :src="product[act_product]?product[act_product].image:''" alt="" />
						<div class="service_list">
							<div class="service_item" ref="serviceItem" @mouseenter="hoverService(index)"
								v-for="(item,index) in product" :key="index">
								<p>{{item.title}}</p>
								<p>{{item.name}}</p>
							</div>
						</div>
					</div>
				</div>
				<!-- 聚焦饮品赛道 -->
				<section class="jujiao flexcol">
					<p class="title">聚焦现象级饮品赛道</p>
					<div class="ju_main">
						<div class="ju_le">
							<div class="ju1 ju_img" @click="chooseSai(0)">
								<p>瓶装饮料</p>
								<p>占据各种便利店铺货量的30%，销售渠道众多</p>
							</div>
							<div class="ju_leb">
								<div class="ju3 ju_img" @click="chooseSai(4)">
									<p>咖啡&冲调类</p>
									<p>方便携带，更有助于保留营养成分和口感</p>
								</div>
								<div class="ju4 ju_img" @click="chooseSai(3)">
									<p>药食同源</p>
									<p>石斛、葛根、陈皮等中药材饮料</p>
								</div>
							</div>
						</div>
						<div class="ju_ri">
							<div class="ju2 ju_img" @click="chooseSai(1)">
								<p>街头即饮</p>
								<p>喜茶、奈雪、蜜雪冰城、茶百道等街头即饮店</p>
							</div>
							<div class="ju5 ju_img" @click="chooseSai(2)">
								<p>低度潮饮</p>
								<p>消费场景夜场酒吧等，果子酒、梅子酒、微醺等</p>
							</div>
						</div>
					</div>
					<p class="title">近期展会&论坛</p>
					<div class="zhan_list">
						<div class="zhan_item" @click="toZh(item)" v-for="(item,index) in exhibition" :key="index">
							<img :src="item.image" alt="" />
							<p>{{item.name}}</p>
							<p>{{item.stime}} - {{item.endtime}}</p>
							<p>{{item.address||''}}</p>
						</div>
					</div>
				</section>
				<!-- 往届嘉宾 -->
				<section class="jiab">
					<p class="title">往届嘉宾</p>
					<div class="jiab_list">
						<div class="jiab_item" v-for="(item,index) in alongguess" :key="index">
							<div class="jiab_img">
								<img :src="item.image" alt="" />
								<div class="red_box"></div>
							</div>
							<p>{{item.name}}</p>
							<p>{{item.postion}}</p>
							<p>{{item.brand}}</p>
						</div>
					</div>
				</section>
				<!-- 嘉宾评价 -->
				<section class="pinjia">
					<p class="title">嘉宾评价</p>
					<div style="width: 80rem;">
						<el-carousel height="20rem" indicator-position="outside" arrow="nerver">
							<el-carousel-item v-for="(item,index) in guesspj" :key="index">
								<div class="pinjia_content">
									<div class="con_le">
										<img :src="item.image" alt="" />
										<div></div>
									</div>
									<div class="con_ri">
										<p class="con_con">{{item.eva}}</p>
										<p class="name">{{item.name}} <span>{{item.postion}}</span> <span>
												{{item.brand}}
											</span> </p>
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</section>
				<!-- 热门视频资讯 -->
				<section class="ship flexcol">
					<p class="title">热门视频资讯</p>
					<div class="ship_main">
						<p @click="lookMore(1)" class="look_more">查看更多 <img src="../assets/arrow_b.png" alt="" /> </p>
						<div class="ship_list">
							<div class="ship_item" @click="showVideo(item)" v-for="(item,index) in video" :key="index">
								<div style="position: relative;">
									<img :src="item.image" class="ship_img" alt="" />
									<img v-if="item.hot==1" src="../assets/tag1.png" class="tag" alt="" />
									<div class="clocks">
										<img src="../assets/clock.png" alt="" />{{item.timelong||'0:00'}}
									</div>
								</div>
								<p>{{item.name}}</p>
							</div>
						</div>
					</div>
					<!-- 客户案例 -->
					<p class="title">客户案例</p>
					<div class="case">
						<p @click="lookMore(2)" class="look_more">查看更多 <img src="../assets/arrow_b.png" alt="" /> </p>
						<div class="case_list">
							<CaseItem  :info='item' v-for="(item,index) in gucase" :key="index" />
						</div>
					</div>
				</section>
				<!-- 联系我们 -->
				<div style="width: 100%;">
					<FootDaohang />
				</div>
			</div>
		</template>
		<!-- 视频像 -->
		<el-dialog :visible.sync="video_show" :show-close="true" @close='changeClose'>
			<video id='videos' class="videos" controls :src="videoInfo.video"></video>
		</el-dialog>
	</div>
</template>

<script>
	import FootDaohang from "./FootDaohang.vue"
	import CaseItem from "./caseItem.vue"
	import mobileHome from "./mobile/mobileHome.vue"
	export default {
		name: 'HomeMain',
		components: {
			FootDaohang,
			CaseItem,
			mobileHome
		},
		data() {
			return {
				videoInfo:{},
				video_show:false,//视频详情
				ismobile: false,
				baseUrl: this.$BaseUrl,
				banner: [], //banner
				product: [], //产品与服务
				act_product: 0, //产品与服务选中
				drinksai: [], //饮品赛道
				alongguess: [], //往届嘉宾
				guesspj: [], //嘉宾评价
				video: [], //视频咨询 
				exhibition: [], //近期展会与论坛
				gucase: [], //客户案例
				images: [
					"https://img.bicobrand.com/assets/images/web/index-logo2.png",
					"https://img.bicobrand.com/assets/images/web/index-logo2.png",
					"https://img.bicobrand.com/assets/images/web/index-logo2.png",
				],
				imgScroll: "",
				menuList: [{
						chs: "展览",
						eng: "exhibit",
						path:"/zszn"
					},
					{
						chs: "论坛",
						eng: "forum",
						path:"/wjlt"
					},
					{
						chs: "资讯",
						eng: "information",
						path:"/twzx"
					},
					{
						chs: "赛事",
						eng: "match",
						path:"/ss"
					},
					{
						chs: "新经略",
						eng: "New Strategy",
						path:"/xjl"
					},
					{
						chs: "研习社",
						eng: "Study Society",
						path:"/yxs"
					}
				], //菜单列表
				numList: [{
						num: "5500+",
						jieshao: "服务品牌",
						nums: 5500,
						numbers: [],
						currentNumber: 0,
						intervalId: "",
					},
					{
						num: "700+",
						jieshao: "参展展商",
						nums: 700,
						numbers: [],
						currentNumber: 0,
						intervalId: "",
					},
					{
						num: "20w+",
						jieshao: "覆盖渠道商",
						nums: 200000,
						numbers: [],
						currentNumber: 0,
						intervalId: "",
					},
					{
						num: "1500+",
						jieshao: "全国渠道商社群",
						nums: 1500,
						numbers: [],
						currentNumber: 0,
						intervalId: "",
					},
				], //数字列表
				duration: 3000,
				numTop: 0,
			}
		},
		created() {
			this.ismobile = this.$isMobile()
			this.getBanner()
			this.getProduct()
			this.getDrinksai()
			this.getAlongguess()
			this.getGuesspj()
			this.getVideo()
			this.getExhibition()
			this.getGucase()
		},
		mounted() {
			if(!this.ismobile){
				this.numTop = this.$refs.numberTop.offsetTop
				window.addEventListener('scroll', this.handleScroll)
				// 图片滚动
				var contentBox = document.getElementsByClassName('imgList')[0]
				var scrollBox = document.getElementsByClassName('box')[0]
				// var imgWidth = 210
				// let _this=this
				this.imgScroll = setInterval(() => {
					scrollBox.scrollLeft++
					//当一张图片恰好滚动至可视区域外
					if (scrollBox.scrollLeft > 0 && scrollBox.scrollLeft % 1920 === 0) {
						var el = contentBox.firstElementChild //获取处于前面的图片
						contentBox.appendChild(el) //appendChild()具备剪切功能，无需手动删除旧节点
						// 获取此时content-box的左内边距，值的形式为'999px'
						var paddingLeftStr = window.getComputedStyle(contentBox).getPropertyValue('padding-left')
						var paddingLeft = parseInt(paddingLeftStr) //转化为数值，如： 999
						contentBox.style.paddingLeft = paddingLeft + 1920 + 'px'
					}
				}, 10)
			}
		},
		beforeDestroy() {
			// 清除定时器
			this.numList.forEach(item => {
				clearInterval(item.intervalId);
			})
			clearInterval(this.imgScroll);
			window.removeEventListener('scroll', this.handleScroll); //监听页面滚动事件
		},
		methods: {
			changeClose(){
				let video=document.getElementById('videos')
				video.pause()
			},
			toPage(item){
				this.$router.push({
					path:item.path
				})
			},
			// 近期展会跳转
			toZh(item){
				if(item.type==1){
					this.$router.push({
						path:"/ljzl",
						query:{
							id:item.id
						}
					})
				}
				if(item.type==2){
					this.$router.push({
						path:"/wjlt",
						query:{
							id:item.id
						}
					})
				}
			},
			// 查看更多 1视频 2客户案例
			lookMore(type){
				if(type==1){
					this.$router.push({
						path:"/spzx"
					})
				}
				if(type==2){
					this.$router.push({
						path:"/khal"
					})
				}
			},
			// 视频详情
			showVideo(item){
				this.videoInfo=item
				this.video_show=true
			},
			// 时间戳转换
			getNowFormatDate(time) { //获取当前时间
				var date = new Date(time * 1000);
				var seperator1 = ".";
				// var seperator2 = ".";
				var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
				var strDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
				var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate;
				// " " + date.getHours() + seperator2 + date.getMinutes() + seperator2 + date.getSeconds();
				return currentdate;
			},
			getTxt(html, num) {
				const myDiv = document.createElement("div");
				myDiv.innerHTML = html;
				let str = myDiv.innerText;
				str = str.substr(0, num) + '...';
				return str;
			},
			// 客户案例
			getGucase() {
				this.$api.gucase().then(res => {
					if (res.code == 1) {
						res.data.status.forEach(item => {
							item.stime = this.getNowFormatDate(item.stime)
							item.content = this.getTxt(item.content, 80)
						})
						this.gucase = res.data.status.splice(0,4)
					}
				})
			},
			// 近期展会与论坛
			getExhibition() {
				this.$api.exhibition().then(res => {
					if (res.code == 1) {
						res.data.status.forEach(item => {
							item.stime = this.getNowFormatDate(item.stime)
							item.endtime = this.getNowFormatDate(item.endtime)
							item['type']=1
						})
						res.data.lt.forEach(item => {
							item.stime = this.getNowFormatDate(item.stime)
							item.endtime = this.getNowFormatDate(item.endtime)
							item['type']=2
						})
						this.exhibition = [...res.data.status,...res.data.lt]
					}
				})
			},
			// 视频咨询
			getVideo() {
				this.$api.video().then(res => {
					if (res.code == 1) {
						this.video = res.data.status
					}
				})
			},
			// 往届嘉宾
			getAlongguess() {
				this.$api.alongguess().then(res => {
					if (res.code == 1) {
						this.alongguess = res.data.status
					}
				})
			},
			// 获取轮播图
			getBanner() {
				this.$api.banner({
					id:0
				}).then(res => {
					if (res.code == 1) {
						this.banner = res.data.status
					}
				})
			},
			// 产品与服务
			getProduct() {
				this.$api.produnct().then(res => {
					if (res.code == 1) {
						this.product = res.data.status
					}
				})
			},
			// 饮品赛道
			getDrinksai() {
				this.$api.drinksai().then(res => {
					if (res.code == 1) {
						this.drinksai = res.data.status
					}
				})
			},
			// 嘉宾评价
			getGuesspj() {
				this.$api.guesspj().then(res => {
					if (res.code == 1) {
						this.guesspj = res.data.status
					}
				})
			},
			// 饮品赛道选择
			chooseSai(index) {
				console.log(this.drinksai[index]);
				this.$router.push({
					path:"/twzx",
					query:{
						id:this.drinksai[index].id
					}
				})
			},
			// 产品服务移入
			hoverService(item) {
				this.act_product = item
				// let el=document.getElementsByClassName("serviceItem")
				this.$refs.serviceItem.forEach((items, indexs) => {
					if (indexs == item) {
						items.style.backgroundColor = "#fff"
						items.style.borderBottom = "4px solid #F40009"
					} else {
						items.style.backgroundColor = "#F7F8FA"
						items.style.borderBottom = ""
					}
				})
			},
			// 改变数字
			changeNum() {
				this.numList.forEach(item => {
					// 初始化数字数组
					item.numbers = item.currentNumber.toString().split('').map(Number);
					// 启动定时器
					item.intervalId = setInterval(() => {
						const increment = Math.ceil((item.nums - item.currentNumber) / (this.duration /
							10));
						item.currentNumber += increment;
						// 更新数字数组
						item.numbers = item.currentNumber.toString().split('').map(Number);
						if (item.currentNumber >= item.nums) {
							clearInterval(item.intervalId);
						}
					}, 10);
				})
			},
			// 滚动事件
			handleScroll() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				// console.log(scrollTop, '滚动距离')
				// console.log(this.numTop);
				if (scrollTop >= this.numTop) {
					this.changeNum()
				}
			},
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.videos{
		width: 100%;
		height: 600px;
	}
	.scroll-container {
		width: 100vw;
		height: 100vh;
		overflow: hidden;
	}

	.slide-enter-active,
	.slide-leave-active {
		transition: transform 5s;
	}

	.slide-enter,
	.slide-leave-to

	/* .slide-leave-active in <2.1.8 */
		{
		transform: translateX(0);
	}

	/deep/.el-carousel__indicators--outside button {
		background-color: red;
	}

	.banner_img {
		width: 100%;
		height: 45rem;
	}

	.menu {
		padding: 6rem 0;
		box-sizing: border-box;
		display: flex;

		.menu_item {
			cursor: pointer;
			width: 12.5rem;
			height: 9rem;
			background: #FFFFFF;
			box-shadow: 0px 3px 6px 1px rgba(106, 122, 188, 0.2);
			border-radius: 0px 0px 0px 0px;
			justify-content: center;
			margin-right: 1rem;

			&:hover {
				box-shadow: 0px 8px 20px 1px rgba(206, 45, 42, 0.20);
			}

			:nth-child(1) {
				font-size: 0.875rem;
				color: #16202B;
				line-height: 23px;
			}

			:nth-child(2) {
				font-size: 1.4375rem;
				font-weight: 600;
				color: #16202B;
				line-height: 20px;
				margin-top: 4px;
				margin-bottom: 0.75rem;
			}

			div {
				font-size: 0.875rem;
				color: #F40009;
				line-height: 20px;
				display: flex;
				align-items: center;

				img {
					width: 0.5rem;
					height: 0.5rem;
					margin-left: 2px;
				}
			}
		}

		:last-child {
			margin-right: 0;
		}
	}

	.jieshao {
		width: 100%;
		// height: 760px;
		background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 56%, #F7F8FA 100%);
		padding-top: 5rem;
		padding-bottom: 5rem;

		.jie {
			font-size: 3.1875rem;
			font-weight: 600;
			color: #16202B;
			line-height: 4rem;
		}

		.shao {
			font-size: 1.75rem;
			color: #16202B;
			line-height: 2.1875rem;
			margin-top: 9px;
		}

		.num_list {
			display: flex;
			align-items: center;
			margin-top: 4rem;

			.num_item {
				padding: 0 4.375rem 0.5rem;
				border-right: 1px solid #B8BBBE;
				box-sizing: border-box;

				.num {
					font-size: 2.875rem;
					font-weight: bold;
					line-height: 3.1875rem;
					color: #F40009;
					animation: number-roll 0.5s ease-in-out;
					display: flex;
					align-items: center;
				}

				.jis {
					font-size: 0.875rem;
					color: #16202B;
					line-height: 1.25rem;
					margin-top: 4px;
				}
			}

			.num_item:last-child {
				border-right: 0;
			}
		}

		.box {
			width: 100%;
			height: 16rem;
			margin: 0 auto;
			overflow: hidden;
			margin-top: 5rem;
			// width: 200%; /* Double the width to accommodate two images */
			//     height: 100vh;
			//     white-space: nowrap;
			//     overflow: hidden;
		}

		.imgList {
			// // animation: rolling 10s linear infinite;
			display: flex;
		}


		img {
			width: 1920px;
			height: 16rem;
			margin-right: 10px;
		}
	}

	.title {
		font-size: 3.1875rem;
		font-weight: 600;
		color: #16202B;
		text-align: center;
	}

	.service {
		width: 100%;
		background: linear-gradient(360deg, #FFFFFF 0%, #F7F8FA 100%);
		padding-top: 5rem;
		padding-bottom: 5rem;

		.service_main {
			width: 80rem;
			margin-top: 2rem;
			font-size: 0;

			img {
				width: 80rem;
				height: 26.5rem;
			}
		}

		.service_list {
			display: flex;
			align-items: center;

			.service_item {
				width: 16rem;
				height: 12.5rem;
				background: #F7F8FA;
				padding: 2rem 1.5rem 0;
				box-sizing: border-box;

				:nth-child(1) {
					font-size: 1.25rem;
					font-weight: 600;
					color: #16202B;
					line-height: 2.375rem;
				}

				:nth-child(2) {
					font-size: 0.875rem;
					color: #73797F;
					line-height: 1.25rem;
					margin-top: 0.75rem;
				}
			}
		}
	}

	.jujiao {
		width: 100%;
		padding: 5rem 0;
		background-color: #F7F8FA;
		box-sizing: border-box;

		.ju_main {
			display: flex;
			color: #FFFFFF;
			margin-top: 3rem;
			margin-bottom: 8rem;

			.ju_le {
				margin-right: 1rem;
			}

			.ju_leb {
				display: flex;
				align-items: center;
				margin-top: 1rem;
			}

			.ju_img {
				cursor: pointer;
				background-size: 100%;
				padding-top: 5.5rem;
				padding-left: 2rem;
				box-sizing: border-box;
				// overflow: hidden;
				transition: all linear .5s;

				&:hover {
					background-size: 110%;

					p {
						transform: scale(1.05);
					}
				}

				p {
					transition: all linear .5s;

					&:nth-child(1) {
						font-size: 2.125rem;
						font-weight: 600;
						line-height: 2.375rem;
					}

					&:nth-child(2) {
						font-size: 1rem;
						line-height: 1.25rem;
						margin-top: 0.5rem;
					}
				}
			}

			.ju1 {
				width: 53rem;
				height: 26rem;
				background-image: url("http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705373078912.png");
			}

			.ju2 {
				width: 26rem;
				height: 17.5rem;
				background-image: url("http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705373162104.png");
			}

			.ju3 {
				width: 26rem;
				height: 17.5rem;
				background-image: url("http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705373179721.png");
				margin-right: 1rem;
			}

			.ju4 {
				width: 26rem;
				height: 17.5rem;
				background-image: url("http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705373206582.png");
			}

			.ju5 {
				width: 26rem;
				height: 26rem;
				background-image: url("http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705373223349.png");
				margin-top: 1rem;
			}
		}

		.zhan_list {
			width: 80rem;
			display: flex;
			// align-items: center;
			margin-top: 2rem;

			.zhan_item {
				cursor: pointer;
				margin-right: 1rem;

				&:last-child {
					margin-right: 0;
				}

				img {
					width: 19.25rem;
					height: 12.5rem;
					margin-top: 1rem;
				}

				p {
					&:nth-of-type(1) {
						font-size: 1.25rem;
						font-weight: 600;
						color: #16202B;
					}

					&:nth-of-type(2) {
						font-size: 0.875rem;
						color: #16202B;
						margin-top: 8px;
						margin-bottom: 4px;
					}

					&:nth-of-type(3) {
						font-size: 0.875rem;
						color: #16202B;
					}
				}
			}
		}
	}

	.jiab {
		padding-top: 5rem;

		.jiab_list {
			width: 80rem;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			// margin-top: 2.5rem;
		}

		.jiab_item {
			width: 15.5rem;
			height: 14rem;
			// background: #F7F8FA;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			margin-top: 2.5rem;
			margin-right: 6rem;

			&:nth-of-type(4n) {
				margin-right: 0;
			}

			p {
				&:nth-of-type(1) {
					font-size: 1.25rem;
					font-weight: 600;
					color: #F40009;
					line-height: 2.375rem;
				}

				&:nth-of-type(2) {
					font-size: 0.875rem;
					color: #73797F;
					line-height: 1.25rem;
				}

				&:nth-of-type(3) {
					font-size: 1rem;
					color: #16202B;
					line-height: 1.5rem;
					margin-top: 0.75rem;
				}
			}

			.jiab_img {
				position: absolute;
				right: 0;
				top: 0;

				img {
					width: 10rem;
					height: 10rem;
				}

				.red_box {
					width: 2rem;
					height: 2rem;
					position: absolute;
					left: -10px;
					bottom: -8px;
					background-color: #F40009;
					transition: all linear .2s;
				}
			}

			&:hover {
				background-color: #F7F8FA;

				.red_box {
					left: 0;
					bottom: 4px;
				}
			}
		}
	}

	.pinjia {
		margin-top: 6.5rem;
		padding-bottom: 4rem;
		box-sizing: border-box;

		.pinjia_content {
			width: 80rem;
			height: 18rem;
			background-image: url("../assets/pj_bg.png");
			background-size: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.con_le {
				position: relative;
				margin-right: 3.5rem;

				img {
					width: 10rem;
					height: 10rem;
				}

				div {
					width: 2rem;
					height: 2rem;
					position: absolute;
					right: -8px;
					bottom: -8px;
					background-color: #F40009;
				}
			}

			.con_ri {
				height: 10rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-top: 1rem;
				box-sizing: border-box;
			}

			.con_con {
				width: 44.5rem;
				font-size: 1rem;
				color: #16202B;
				line-height: 1.5rem;
			}

			.name {
				font-size: 1.25rem;
				font-weight: 600;
				color: #F40009;
				line-height: 2.375rem;

				span {
					font-size: 0.875rem;
					font-weight: 400;
					color: #16202B;
					line-height: 1.5rem;
					margin-left: 1.25rem;
				}
			}
		}
	}

	.ship {
		width: 100%;
		background-color: #F7F8FA;
		padding-top: 5rem;
		padding-bottom: 7.5rem;

		.look_more {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			font-size: 0.875rem;
			color: #444C55;
			line-height: 1.25rem;

			img {
				width: 0.5rem;
				height: 0.5rem;
				margin-left: 4px;
			}
		}

		.ship_main {
			width: 80rem;
			margin-bottom: 7.5rem;

			.ship_list {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				padding-top: 1rem;
				box-sizing: border-box;
			}

			.ship_item {
				cursor: pointer;
				width: 19.25rem;
				margin-right: 1rem;
				margin-top: 1.5rem;
				font-size: 0;

				&:nth-of-type(4n) {
					margin-right: 0;
				}

				font-size: 0.875rem;
				color: #16202B;
				line-height: 1.25rem;
			}

			.ship_img {
				width: 19.25rem;
				height: 12.5rem;
			}

			.tag {
				width: 3.5rem;
				height: 2rem;
				position: absolute;
				top: 0;
				left: 0;
			}

			.clocks {
				width: 4.5rem;
				height: 1.5rem;
				background: rgba(0, 0, 0, 0.2);
				border-radius: 16px 16px 16px 16px;
				opacity: 0.8;
				border: 1px solid #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0.875rem;
				color: #FFFFFF;
				position: absolute;
				left: 0.5rem;
				bottom: 0.5rem;

				img {
					width: 0.75rem;
					height: 0.75rem;
					margin-right: 4px;
				}
			}
		}

		.case {
			width: 80rem;

			.case_list {
				display: flex;
				align-items: center;
			}
		}
	}
</style>