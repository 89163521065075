<template>
	<div>
		<!-- 联系我们 -->
		<section class="contact flexcol">
			<p class="con_content">联系我们，品饮汇为您提供最专业的饮品行业咨询服务</p>
			<div class="zixun" @click="tolx">
				立即咨询<img src="../assets/arrow_c.png" alt="" />
			</div>
			<div class="zixun_list">
				<div class="zixun_item" v-for="(item,index) in zixunList" :key="index">
					<img :src="item.src" alt="" />{{item.name}}
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default{
		data() {
			return {
				zixunList: [{
						src: require("@/assets/zixun1.png"),
						name: "饮品主题展"
					},
					{
						src: require("@/assets/zixun2.png"),
						name: "行业论坛"
					},
					{
						src: require("@/assets/zixun3.png"),
						name: "中饮路演秀"
					},
					{
						src: require("@/assets/zixun4.png"),
						name: "新经略"
					},
					{
						src: require("@/assets/zixun5.png"),
						name: "饮品研习社"
					}
				], //咨询列表
			}
		},
		methods:{
			tolx(){
				this.$router.push({
					path:"/lxwm"
				})
			}
		}
	}
</script>

<style lang="less">
	.contact {
		width: 100%;
		height: 20.5rem;
		background-image: url("http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705373272933.png");
		background-size: 100%;
	
		.con_content {
			font-size: 2.125rem;
			font-weight: 600;
			color: #FFFFFF;
			line-height: 2.875rem;
			margin-top: 3rem;
		}
	
		.zixun {
			cursor: pointer;
			width: 12.5rem;
			height: 4rem;
			background: #FFFFFF;
			font-size: 1rem;
			font-weight: 600;
			color: #F40009;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 2rem;
			margin-bottom: 4.5rem;
	
			img {
				width: 1rem;
				height: 1rem;
				margin-left: 1.25rem;
			}
		}
	
		.zixun_list {
			width: 80rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	
		.zixun_item {
			display: flex;
			align-items: center;
			font-size: 1rem;
			font-weight: 600;
			color: #16202B;
	
			img {
				width: 2.5rem;
				height: 2.5rem;
				margin-right: 0.5rem;
			}
		}
	}
</style>