<template>
	<div class="flexcol mobileHome">
		<!-- 轮播 -->
		<div style="width: 100%;">
			<el-carousel height="142px">
				<el-carousel-item v-for="(item,index) in banner" :key="index">
					<img :src="item.image" class="banner_img" alt="" />
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 菜单 -->
		<div class="menu" ref="numberTop">
			<div class="menu_item flexcol" @click="toPage(item)" v-for="(item,index) in menuList" :key="index">
				<p>{{item.eng}}</p>
				<p>{{item.chs}}</p>
				<!-- <div>进一步了解<img src="@/assets/arrow_red.png" alt="" /></div> -->
			</div>
		</div>
		<!-- 介绍 -->
		<section class="mobile_sec">
			<div class="jieshao flexcol">
				<div class="jie">
					深度聚焦饮品行业的链接服务平台
				</div>
				<p class="shao">
					专注饮品行业链接服务赋能：品牌方、经销商、产业链
				</p>
				<!-- 数字滚动 -->
				<div class="num_list">
					<div class="num_item flexcol" v-for="(item,index) in numList" :key="index">
						<div class="num">
							{{item.num}}
						</div>
						<div class="jis">{{item.jieshao}}</div>
					</div>
				</div>
				<!-- 长图滚动 -->
				<div class="box">
					<div class="imgList">
						<!-- <img v-for="(item,index) in images" :key="index" :src="item" alt="" /> -->
						<img src="https://img.bicobrand.com/assets/images/web/index-logo2.png" alt="" />
						<img src="https://img.bicobrand.com/assets/images/web/index-logo2.png" alt="" />
						<img src="https://img.bicobrand.com/assets/images/web/index-logo2.png" alt="" />
					</div>
				</div>
			</div>
		</section>
		<!-- 品饮汇产品与服务 -->
		<section class="mobile_sec">
			<div class="service flexcol">
				<p class="title" style="margin-top: 10px;">品饮汇产品与服务</p>
				<div class="service_main">
					<el-carousel height="250px" indicator-position="outside">
						<el-carousel-item v-for="(item,index) in product" :key="index">
							<img :src="item.image" alt="" />
							<div class="service_content">
								{{item.title}}
							</div>
							<div class="service_content">
								<p>{{item.name}}</p>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</section>
		<!-- 聚焦饮品赛道 -->
		<section class="mobile_sec">
			<section class="jujiao flexcol">
				<p class="title">聚焦现象级饮品赛道</p>
				<div class="ju_main">
					<div class="ju_le">
						<div class="ju1 ju_img" @click="chooseSai(0)">
							<p>瓶装饮料</p>
							<!-- <p>占据各种便利店铺货量的30%，销售渠道众多</p> -->
						</div>
						<div class="ju_leb">
							<div class="ju3 ju_img" @click="chooseSai(4)">
								<p>咖啡&冲调类</p>
								<!-- <p>方便携带，更有助于保留营养成分和口感</p> -->
							</div>
							<div class="ju4 ju_img" @click="chooseSai(3)">
								<p>药食同源</p>
								<!-- <p>石斛、葛根、陈皮等中药材饮料</p> -->
							</div>
						</div>
					</div>
					<div class="ju_ri">
						<div class="ju2 ju_img" @click="chooseSai(1)">
							<p>街头即饮</p>
							<!-- <p>喜茶、奈雪、蜜雪冰城、茶百道等街头即饮店</p> -->
						</div>
						<div class="ju5 ju_img" @click="chooseSai(2)">
							<p>低度潮饮</p>
							<!-- <p>消费场景夜场酒吧等，果子酒、梅子酒、微醺等</p> -->
						</div>
					</div>
				</div>
			</section>
		</section>
		<section class="mobile_sec">
			<div class="zhan_main">
				<p class="title">近期展会&论坛</p>
				<div class="zhan_list">
					<div class="zhan_item" @click="toZh(item)" v-for="(item,index) in exhibition" :key="index">
						<p>{{item.name}}</p>
						<img :src="item.image" alt="" />
						<div class="zhan_foot">
							<p>{{item.stime}} - {{item.endtime}}</p>
							<p>{{item.address}}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- 往届嘉宾 -->
		<section class="mobile_sec">
			<section class="jiab">
				<p class="title">往届嘉宾</p>
				<el-carousel style="width: 100%;" height="360px" indicator-position="outside">
					<el-carousel-item v-for="(item,index) in jiaList" :key="index">
						<div class="jiab_list">
							<div class="jiab_item flexcol" v-for="(items,indexs) in item" :key="indexs">
								<img :src="items.image" alt="" />
								<p>{{items.name}}</p>
								<p>{{items.postion}}</p>
								<p>{{items.brand}}</p>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</section>
			<!-- 嘉宾评价 -->
			<section class="pinjia">
				<p class="title">嘉宾评价</p>
				<div style="margin-top: 16px;">
					<el-carousel height="130px" indicator-position="outside" arrow="nerver">
						<el-carousel-item v-for="(item,index) in guesspj" :key="index">
							<div class="pinjia_content">
								<div class="con_le">
									<img :src="item.image" alt="" />
									<div class="con_ri">
										<p class="name">{{item.name}} <span>{{item.postion}}</span></p>
										<p class="con_con">{{item.brand}}</p>
									</div>
								</div>
								<p class="con_content">{{item.eva}}</p>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</section>
		</section>
		<!-- 热门视频资讯 -->
		<section class="mobile_sec">
			<div class="ship_main">
				<p class="title">热门视频资讯</p>
				<div class="ship_list">
					<div class="ship_item" @click="lookVideo(item)" v-for="(item,index) in video" :key="index">
						<div style="position: relative;">
							<img :src="item.image" class="ship_img" alt="" />
							<img src="@/assets/tag1.png" class="tag" alt="" />
							<div class="clocks">
								<img src="@/assets/clock.png" alt="" />{{item.timelong||'0:00'}}
							</div>
						</div>
						<p>{{item.name}}</p>
					</div>
				</div>
			</div>
		</section>
		<section class="mobile_sec">
			<section class="ship flexcol">
				<!-- 客户案例 -->
				<div class="case">
					<p class="title">客户案例</p>
					<div class="case_list">
						<div class="case_item" @click="toKh(item)" v-for="(item,index) in gucase" :key="index">
							<p class="case_title">{{item.name}}</p>
							<img :src="item.image" alt="" />
							<p class="case_content">
								{{item.content}}
							</p>
							<p class="case_stime">
								{{item.stime}}
							</p>
						</div>
					</div>
				</div>
			</section>
		</section>
		<!-- 联系我们 -->
		<section class="contact_us flexcol">
			<div class="contents">联系我们，品饮汇为您提供最专业的饮品行业咨询服务</div>
			<div class="con_btns" @click="lxwom">立即咨询</div>
		</section>
		<!-- <div style="width: 100%;">
			<FootDaohang />
		</div> -->
		<!-- 视频像 -->
		<el-dialog :visible.sync="video_show" width="80%" :show-close="true" @close='changeClose'>
			<video id="videos" class="videosh" controls :src="videoInfo.video"></video>
		</el-dialog>
	</div>
</template>

<script>
	// import FootDaohang from "./FootDaohang.vue"
	// import CaseItem from "../caseItem.vue"
	export default {
		name: 'HomeMain',
		components: {
			// FootDaohang,
			// CaseItem
		},
		props: {
			numList: {
				type: Array,
			},
			product: {
				type: Array,
			},
			banner: {
				type: Array,
			},
			drinksai: {
				type: Array,
			},
			alongguess: {
				type: Array,
			},
			guesspj: {
				type: Array,
			},
			video: {
				type: Array,
			},
			exhibition: {
				type: Array
			},
			gucase: {
				type: Array
			},
			menuList: {
				type: Array
			}
		},
		data() {
			return {
				videoInfo:{},
				baseUrl: this.$BaseUrl,
				act_product: 0, //产品与服务选中
				images: [
					"https://img.bicobrand.com/assets/images/web/index-logo2.png",
					"https://img.bicobrand.com/assets/images/web/index-logo2.png",
					"https://img.bicobrand.com/assets/images/web/index-logo2.png",
				],
				imgScroll: "",
				duration: 3000,
				numTop: 0,
				video_show:false,
			}
		},
		computed:{
			jiaList() {
				let arr = []
				if (this.alongguess && this.alongguess.length > 0) {
					for (let i = 0; i < this.alongguess.length; i += 4) {
						arr.push(this.alongguess.slice(i, i + 4))
					}
				}
				return arr
			}
		},
		mounted() {
			// this.numTop = this.$refs.numberTop.offsetTop/
			// window.addEventListener('scroll', this.handleScroll)
			// 图片滚动
			var contentBox = document.getElementsByClassName('imgList')[0]
			var scrollBox = document.getElementsByClassName('box')[0]
			this.imgScroll = setInterval(() => {
				scrollBox.scrollLeft++
				//当一张图片恰好滚动至可视区域外
				if (scrollBox.scrollLeft > 0 && scrollBox.scrollLeft % 1920 === 0) {
					var el = contentBox.firstElementChild //获取处于前面的图片
					contentBox.appendChild(el) //appendChild()具备剪切功能，无需手动删除旧节点
					// 获取此时content-box的左内边距，值的形式为'999px'
					var paddingLeftStr = window.getComputedStyle(contentBox).getPropertyValue('padding-left')
					var paddingLeft = parseInt(paddingLeftStr) //转化为数值，如： 999
					contentBox.style.paddingLeft = paddingLeft + 1920 + 'px'
				}
			}, 10)
			// this.changeNum()
		},
		beforeDestroy() {
			// 清除定时器
			this.numList.forEach(item => {
				clearInterval(item.intervalId);
			})
			clearInterval(this.imgScroll);
		},
		methods: {
			lxwom(){
				this.$router.push({
					path:"/lxwm"
				})
			},
			toPage(item){
				this.$emit('toPage',item)
			},
			changeClose(){
				let video=document.getElementById('videos')
				video.pause()
			},
			lookVideo(item){
				this.videoInfo=item
				this.video_show=true
			},
			// 跳转展会
			toZh(item){
				this.$emit('toZh',item)
			},
			// 时间戳转换
			getNowFormatDate(time) { //获取当前时间
				var date = new Date(time * 1000);
				var seperator1 = ".";
				// var seperator2 = ".";
				var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
				var strDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
				var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate;
				// " " + date.getHours() + seperator2 + date.getMinutes() + seperator2 + date.getSeconds();
				return currentdate;
			},
			getTxt(html, num) {
				const myDiv = document.createElement("div");
				myDiv.innerHTML = html;
				let str = myDiv.innerText;
				str = str.substr(0, num) + '...';
				return str;
			},
			toKh(item){
				// this.$emit('toDeatail',this.info)
				this.$router.push({
					path:"/khxq",
					query:{
						id:item.id
					}
				})
			},
			// 饮品赛道选择
			chooseSai(index) {
				console.log(index,3);
			},
			// 产品服务移入
			hoverService(item) {
				this.act_product = item
				// let el=document.getElementsByClassName("serviceItem")
				this.$refs.serviceItem.forEach((items, indexs) => {
					if (indexs == item) {
						items.style.backgroundColor = "#fff"
						items.style.borderBottom = "1px solid #F40009"
					} else {
						items.style.backgroundColor = "#F7F8FA"
						items.style.borderBottom = ""
					}
				})
			},
			// 改变数字
			changeNum() {
				this.numList.forEach(item => {
					// 初始化数字数组
					item.numbers = item.currentNumber.toString().split('').map(Number);
					// 启动定时器
					item.intervalId = setInterval(() => {
						const increment = Math.ceil((item.nums - item.currentNumber) / (this.duration /
							10));
						item.currentNumber += increment;
						// 更新数字数组
						item.numbers = item.currentNumber.toString().split('').map(Number);
						if (item.currentNumber >= item.nums) {
							clearInterval(item.intervalId);
						}
					}, 10);
				})
			},
			// 滚动事件
			handleScroll() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				// console.log(scrollTop, '滚动距离')
				// console.log(this.numTop);
				if (scrollTop >= this.numTop) {
					this.changeNum()
				}
			},

		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.mobileHome {
		background-color: #FAFAFA;

		.scroll-container {
			width: 100vw;
			height: 100vh;
			overflow: hidden;
		}

		.slide-enter-active,
		.slide-leave-active {
			transition: transform 5s;
		}

		.slide-enter,
		.slide-leave-to

		/* .slide-leave-active in <2.1.8 */
			{
			transform: translateX(0);
		}

		/deep/.el-carousel__indicators--outside button {
			background-color: red;
		}

		.mobile_sec {
			width: 100%;
			padding: 10px 1.5rem;
			box-sizing: border-box;
		}

		.banner_img {
			width: 100%;
			height: 142px;
		}

		.menu {
			padding: 10px 1.5rem;
			box-sizing: border-box;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.menu_item {
				width: 13.75rem;
				height: 56px;
				background: #FFFFFF;
				border-radius: 4px 4px 4px 4px;
				justify-content: center;
				// margin-right: 1rem;
				margin-top: 10px;

				&:hover {
					box-shadow: 0px 8px 20px 1px rgba(206, 45, 42, 0.20);
				}

				:nth-child(1) {
					font-size: 0.875rem;
					color: #16202B;
					// line-height: 23px;
				}

				:nth-child(2) {
					font-size: 1.4375rem;
					font-weight: 600;
					color: #16202B;
					// line-height: 20px;
					margin-top: 4px;
					margin-bottom: 0.75rem;
				}

				div {
					font-size: 0.875rem;
					color: #F40009;
					line-height: 20px;
					display: flex;
					align-items: center;

					img {
						width: 0.5rem;
						height: 0.5rem;
						margin-left: 2px;
					}
				}
			}

			:last-child {
				margin-right: 0;
			}
		}

		.jieshao {
			width: 100%;
			// height: 760px;
			background: #fff;
			padding-top: 16px;
			padding-bottom: 16px;

			.jie {
				font-size: 18px;
				font-weight: 600;
				color: #333;
			}

			.shao {
				font-size: 12px;
				color: #666666;
				margin-top: 12px;
			}

			.num_list {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-top: 24px;

				.num_item {
					width: 50%;
					height: 68px;
					justify-content: center;

					&:nth-of-type(1) {
						border-bottom: 2px solid #E8E8E8;
						border-right: 2px solid #E8E8E8;
					}

					&:nth-of-type(2) {
						border-bottom: 2px solid #E8E8E8;
					}

					&:nth-of-type(3) {
						border-right: 2px solid #E8E8E8;
					}

					box-sizing: border-box;

					.num {
						font-size: 28px;
						font-weight: 500;
						color: #333333;
					}

					.jis {
						font-size: 12px;
						color: #666666;
						margin-top: 4px;
					}
				}

				.num_item:last-child {
					border-right: 0;
				}
			}

			.box {
				width: 100%;
				height: 16rem;
				margin: 0 auto;
				overflow: hidden;
				margin-top: 28px;
				// width: 200%; /* Double the width to accommodate two images */
				//     height: 100vh;
				//     white-space: nowrap;
				//     overflow: hidden;
			}

			.imgList {
				// // animation: rolling 10s linear infinite;
				display: flex;
			}


			img {
				width: 1920px;
				height: 16rem;
				margin-right: 10px;
			}
		}

		.title {
			color: #333333;
			font-size: 18px;
			font-weight: 600;
			text-align: center;
		}

		.service {
			width: 100%;
			background: #fff;
			padding: 2rem;
			box-sizing: border-box;

			.service_main {
				width: 100%;
				margin-top: 2rem;
				font-size: 0;

				img {
					width: 100%;
					height: 160px;
				}
			}

			.service_content {
				width: 100%;
				// padding: 10px 0;
				font-size: 14px;
				margin-top: 1.5rem;
			}

		}

		.jujiao {
			width: 100%;
			padding: 16px 0;
			background-color: #fff;
			box-sizing: border-box;

			.ju_main {
				display: flex;
				color: #FFFFFF;
				margin-top: 20px;
				// margin-bottom: 50px;

				.ju_le {
					margin-right: 6px;
				}

				.ju_leb {
					display: flex;
					align-items: center;
					margin-top: 6px;
				}

				.ju_img {
					background-size: 100%;
					padding-top: 19px;
					padding-left: 6px;
					box-sizing: border-box;
					// overflow: hidden;
					transition: all linear .5s;

					&:hover {
						background-size: 110%;

						p {
							transform: scale(1.05);
						}
					}

					p {
						transition: all linear .5s;

						&:nth-child(1) {
							font-size: 2rem;
							font-weight: 600;
						}

						&:nth-child(2) {
							font-size: 0.5rem;
							margin-top: 0.5rem;
						}
					}
				}

				.ju1 {
					width: 230px;
					height: 112px;
					background-image: url("@/assets/ju1.png");
					margin-bottom: 8px;
				}

				.ju2 {
					width: 112px;
					height: 75px;
					background-image: url("@/assets/ju2.png");
				}

				.ju3 {
					width: 112px;
					height: 75px;
					background-image: url("@/assets/ju3.png");
					margin-right: 8px;
				}

				.ju4 {
					width: 112px;
					height: 75px;
					background-image: url("@/assets/ju4.png");
				}

				.ju5 {
					width: 112px;
					height: 112px;
					background-image: url("@/assets/ju5.png");
					margin-top: 8px;
				}
			}
		}

		.zhan_main {
			width: 100%;
			background: #fff;
			padding: 2rem;
			box-sizing: border-box;

			.zhan_list {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 6px;
				flex-wrap: wrap;

				.zhan_item {
					width: 100%;
					margin-top: 16px;

					.zhan_foot {
						display: flex;
						align-items: center;
						justify-content: space-between;

						p {
							font-size: 10px;
							color: #666666;
						}
					}

					img {
						width: 100%;
						height: 207px;
						margin: 12px 0;
					}

					p {
						font-size: 12px;
						font-weight: 500;
						color: #333333;
					}
				}
			}
		}

		.jiab {
			width: 100%;
			padding: 16px 15px 0;
			box-sizing: border-box;
			background-color: #fff;

			.jiab_list {
				width: 100%;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}

			.jiab_item {
				width: 50%;
				margin-top: 24px;
				p {

					&:nth-of-type(1) {
						font-size: 14px;
						font-weight: 500;
						color: #333333;
						margin-top: 8px;
					}

					&:nth-of-type(2) {
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						margin: 8px 0;
					}

					&:nth-of-type(3) {
						font-size: 14px;
						color: #666666;
					}
				}

				img {
					width: 64px;
					height: 64px;
				}

				&:hover {
					background-color: #F7F8FA;

					.red_box {
						left: 0;
						bottom: 4px;
					}
				}
			}
		}

		.pinjia {
			width: 100%;
			padding: 0 15px;
			padding-top: 16px;
			box-sizing: border-box;
			background-color: #fff;

			.pinjia_content {
				width: 100%;
				.con_le{
					display: flex;
					align-items: center;
					margin-bottom: 12px;
				}
				.con_ri{
					height: 49px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.name{
						font-size: 14px;
						color: #333333;
						span{
							font-size: 12px;
							color: #666666;
							margin-left: 8px;
						}
					}
					.con_con{
						font-size: 12px;
						color: #666666;
					}
				}
				.con_content{
					font-size: 12px;
					color: #333333;
				}
				img{
					width: 49px;
					height: 49px;
					margin-right: 8px;
				}
			}
		}
		.ship_main {
			width: 100%;
			background-color: #fff;
			padding: 2rem;
			box-sizing: border-box;
		
			.ship_list {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				padding-top: 10px;
				box-sizing: border-box;
			}
		
			.ship_item {
				width: 100%;
				margin-top: 16px;
				font-size: 14px;
				color: #16202B;
			}
		
			.ship_img {
				width: 100%;
				height: 207px;
			}
		
			.tag {
				width: 40px;
				height: 22.5px;
				position: absolute;
				top: 0;
				left: 0;
			}
		
			.clocks {
				width: 38px;
				height: 12px;
				background: rgba(0, 0, 0, 0.2);
				border-radius: 16px 16px 16px 16px;
				opacity: 0.8;
				border: 1px solid #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				color: #FFFFFF;
				position: absolute;
				left: 10px;
				bottom: 10px;
		
				img {
					width: 8px;
					height: 8px;
					margin-right: 2px;
				}
			}
		}
		.ship {
			width: 100%;
			background-color: #fff;
			padding: 2rem;
			box-sizing: border-box;

			.look_more {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				font-size: 0.875rem;
				color: #444C55;
				line-height: 1.25rem;

				img {
					width: 0.5rem;
					height: 0.5rem;
					margin-left: 4px;
				}
			}
			.case {
				width: 100%;
				padding: 0 15px;
				box-sizing: border-box;

				.case_list {}

				.case_item {
					// height: 75px;
					// display: flex;
					// align-items: center;
					// border-bottom: 1px solid #D0D2D4;
					margin-top: 20px;

					img {
						width: 100%;
						height: 207px;
					}
					.case_title {
						font-size: 14px;
						font-weight: 500;
						color: #333333;
						margin-bottom: 12px;
					}

					.case_content {
						margin-top: 4px;
						font-size: 10px;
						color: #666666;
						overflow: hidden;
						text-overflow: ellipsis;
						word-break: break-all;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						margin-top: 12px;
						margin-bottom: 8px;
					}
					.case_stime{
						font-size: 10px;
						color: #333333;
					}
				}
			}
		}
	}
	.contact_us{
		width: 100%;
		height: 93px;
		background-color: #F40009;
		justify-content: center;
		.contents{
			width: 208px;
			font-size: 12px;
			font-weight: 500;
			color: #FFFFFF;
			text-align: center;
		}
		.con_btns{
			width: 94px;
			height: 32px;
			background: #FFFFFF;
			border-radius: 4px 4px 4px 4px;
			font-size: 12px;
			font-weight: 500;
			color: #F40009;
			text-align: center;
			line-height: 32px;
			margin-top: 4px;
		}
	}
	.videosh{
		width: 100%;
		height: 300px;
	}
</style>