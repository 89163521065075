import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./utils/rem.js"
import  VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import api from "./http";

Vue.prototype.$api = api;
Vue.prototype.$BaseUrl = "http://127.0.0.1/";
Vue.use(VueQuillEditor)

Vue.config.productionTip = false
Vue.use(ElementUI, {
	size: 'small',
	zIndex: 3000
})
// 判断当前设备为手机还是pc //true=为手机  false为pc
Vue.prototype.$isMobile = () => {
	let flag = navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	)
	return flag;
}

// 页面跳转自动回滚到顶部
router.afterEach(() => {
	// window.scrollTo(0, 0);
	// 或
	window.scroll(0, 0);
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')