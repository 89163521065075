import Axios from "axios";
let service = Axios.create({
	// baseURL: "https://crm.pinyinhui.cn/addons/qingdong/", 
	baseURL: 'https://cadmin.pinyinhui.cn/index.php/api',
	// baseURL:"/api",
	timeout: 10000,
});
let service1 = Axios.create({
	baseURL: 'https://app.pinyinhui.cn',
	// baseURL:"http://192.168.101.21:20000",
	// baseURL:"/pin",
	timeout: 10000,
});
// Axios.defaults.baseURL ='/api';
service.interceptors.request.use(
	config => {
		// let token=JSON.parse(window.localStorage.getItem('user'))?JSON.parse(window.localStorage.getItem('user')).token:''
		if(JSON.parse(window.localStorage.getItem('token'))){
			config.headers['token'] =JSON.parse(window.localStorage.getItem('token'))
		}
		// config.headers["Content-Type"]='form-data'
		return config;
	},
	err => {
		console.log(err);
		Promise.reject(err);
	}
);
service.interceptors.response.use(
	response => {
		// console.log(response);
		// response.data["data"] = JSON.parse(data)
		return response.data;
	},
	err => {
		// console.log(err.response);
		if (err.response) {
			if (err.response.status == 500) {
				return { data: { code: 500 } }
			}
		}
		return err;
	}
);
service1.interceptors.request.use(
	config => {
		return config;
	},
	err => {
		console.log(err);
		Promise.reject(err);
	}
);
service1.interceptors.response.use(
	response => {
		return response.data;
	},
	err => {
		if (err.response) {
			if (err.response.status == 500) {
				return { data: { code: 500 } }
			}
		}
		return err;
	}
);

export default { service,service1 };
