<template>
	<div id="app">
		<div v-show="!(path ==='/yqh'||path ==='/gzzn'||path ==='/ljnr')">
			<template>
				<!-- <div v-if="isMobile"></div> -->
				<div v-if="isMobile" class="phone" slot="title">
					<img class="logo_img"
						src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705389286045.png"
						alt="">
					<template>
						<img @click="drawer=true" src="@/assets/phone_menu.png" class="phone_menu" alt="">
					</template>
				</div>
				<nav v-else>
					<img src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705389286045.png"
						class="logo" alt="" />
					<div class="tabs">
						<div v-for="(item,index) in tabList" :key="index" class="menu_item">
							<template>
								<router-link v-if="!item.isList" :to="item.path">{{item.name}}</router-link>
								<div v-if="item.isList" class="menu_top">
									<div class="bottom_menu">
										<div>{{item.name}}</div>
										<img src="@/assets/nav_arrow.png" alt="" />
									</div>
									<div class="menu_child" v-for="(items,indexs) in item.list" :key="indexs">
										<div class="child_item">
											<router-link :to="items.path">{{items.name}}</router-link>
										</div>
									</div>
								</div>
							</template>
						</div>
						<!-- <div class="line"></div> -->
						<div class="menu_top">
							<div class="bottom_menu">
								<div>关于我们</div>
								<img src="@/assets/nav_arrow.png" alt="" />
							</div>
							<div class="menu_child">
								<!-- <div class="child_item">
									<router-link to="/zzsml">赞助商名录</router-link>
								</div> -->
								<div class="child_item">
									<router-link to="/lxwm">联系我们</router-link>
								</div>
								<div class="child_item">
									<router-link to="/gywm">关于我们</router-link>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</template>
			<template>
				<div class="jianju_phone" v-if="isMobile"></div>
				<div class="jianju" v-else></div>
			</template>
		</div>
		<router-view />
		<div v-show="!(path ==='/yqh'||path ==='/gzzn'||path ==='/ljnr')">
			<template>
				<!-- 手机底部 -->
				<div v-if="isMobile" class="foot_phone">
					<!-- 联系方式 -->
					<div class="foot_left">
						<div class="lianxi">
							<div>
								合作热线:13688483262
							</div>
							<div>
								咨询热线:19102811607
							</div>
						</div>
						<div style="display: flex;align-items: center;margin-top: 10px;justify-content: center;">
							<div class="qrcode flexcol" style="margin-right: 10px;">
								<img src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705374119965.jpg"
									alt="" />公众号
							</div>
							<div class="qrcode flexcol">
								<img src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705374156824.jpg"
									alt="" />严选小程序
							</div>
						</div>
					</div>
					<div class="foot_texts">
						<div>
							<a href="https://beian.miit.gov.cn/" title="成都创智公关策划有限公司"
								target="_blank">成都创智公关策划有限公司@蜀ICP备2021031193号</a>
						</div>
            <div>
              <a href="https://beian.miit.gov.cn/" title="成都创智公关策划有限公司"
                 target="_blank">成都创智公关策划有限公司@蜀ICP备11000228号</a>
            </div>
					</div>
				</div>
				<div v-else class="foot">
					<div class="foot_main">
						<!-- 联系方式 -->
						<div class="foot_left">
							<img src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705389286045.png"
								class="logo" alt="" />
							<div class="lianxi">
								<div>
									<p>合作热线</p>
									<p>13688483262</p>
								</div>
								<div>
									<p>咨询热线</p>
									<p>19102811607</p>
								</div>
							</div>
							<div class="address">
								<p>公司地址</p>
								<p>成都锦江区泰合国际财富中心7栋商业裙楼702号</p>
							</div>
							<p style="color: #16202B;font-weight: 600;">相关链接</p>
							<div style="display: flex;align-items: center;margin-top: 10px;">
								<div class="qrcode flexcol" style="margin-right: 1rem;">
									<img src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705374119965.jpg"
										alt="" />公众号
								</div>
								<div class="qrcode flexcol">
									<img src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705374156824.jpg"
										alt="" />严选小程序
								</div>
							</div>
						</div>
						<!-- 底部导航 -->
						<div class="daohang">
							<div class="daohang_item first_daohang" v-for="(item,index) in footList" :key="index">
								<p>{{item.name}}</p>
								<router-link v-for="(items,indexs) in item.list" :key="indexs"
									:to="items.path">{{items.name}}</router-link>
							</div>
						</div>
					</div>
					<div class="beian">
						<div>
							<a href="https://beian.miit.gov.cn/" title="成都创智公关策划有限公司"
								target="_blank">成都创智公关策划有限公司@蜀ICP备2021031193号</a>

              <a href="https://beian.miit.gov.cn/" title="成都创智公关策划有限公司"
                 target="_blank" style="margin-left: 10px;">成都创智公关策划有限公司@蜀ICP备11000228号</a>
						</div>
					</div>
				</div>
			</template>
		</div>
		<!-- 手机菜单 -->
		<el-drawer :visible.sync="drawer" size="446" :direction="direction" :show-close="false" :with-header="true">
			<div class="phone" style="position: relative;z-index: 0;" slot="title">
				<img class="logo_img"
					src="http://cdn.pinyinhui.cn/wx17dac40ca9d25661/aabf06d16756d68236456e5d3a5013c5/1705389286045.png"
					alt="">
				<template>
					<img v-if="!drawer" @click="drawer=true" src="@/assets/phone_menu.png" class="phone_menu" alt="">
					<img v-else @click="drawer=false" src="@/assets/close_menu.png" class="phone_menu" alt="">
				</template>
			</div>
			<ul class="phone_ul">
				<li v-for="(item,index) in tabList" :key="index">
					<template>
						<div v-if="item.isList">
							<div @click="openMenu(index,1)"
								style="display: flex;align-items: center;justify-content: space-between;font-weight: 700;">
								{{item.name}}
								<img src="@/assets/arrow_b.png" style="width: 10px;height: 12px;" alt="" />
							</div>
							<div :id="'content'+index"
								style="height:0;transition: height 0.2s;opacity:0;overflow: hidden;margin-top: 0px;">
								<div style="height: 30px;line-height: 30px;" v-for="(items,indexs) in item.list"
									:key="indexs">
									<router-link :to="items.path">{{items.name}}</router-link>
								</div>
							</div>
						</div>
						<router-link v-else :to="item.path">{{item.name}}</router-link>
					</template>
				</li>
				<li>
					<div>
						<div @click="openMenu(8,1)"
							style="display: flex;align-items: center;justify-content: space-between;font-weight: 700;">
							关于我们
							<img src="@/assets/arrow_b.png" style="width: 10px;height: 12px;" alt="" />
						</div>
						<div id="content8"
							style="height:0;transition: height 0.2s;opacity:0;overflow: hidden;margin-top: 0px;">
							<div style="height: 30px;line-height: 30px;">
								<router-link to="/zzsml">赞助商名录</router-link>
							</div>
							<div style="height: 30px;line-height: 30px;">
								<router-link to="lxwm">联系我们</router-link>
							</div>
							<div style="height: 30px;line-height: 30px;">
								<router-link to="gywm">关于我们</router-link>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				path: "",
				activeNames: [],
				direction: 'ttb', //手机菜单方向
				drawer: false, //手机菜单开关
				isMobile: false, //是否为手机
				tabList: [{
						isList: false,
						name: "首页",
						path: "/"
					},
					{
						isList: true,
						name: "展览",
						list: [{
								name: "我要参展",
								path: "/wycz"
							},
							{
								name: "展商指南",
								path: "/zszn"
							},
							{
								name: "展商名录",
								path: "/zsml"
							},
							// {
							// 	name: "活动专区",
							// 	path: "/hdzq"
							// },
							{
								name: "历届展览",
								path: "/ljzl"
							},
						]
					},
					{
						isList: true,
						name: "论坛",
						list: [{
								name: "往届论坛",
								path: "/wjlt"
							},
							{
								name: "往届嘉宾",
								path: "/wjjb"
							},
						]
					},
					{
						isList: true,
						name: "资讯",
						list: [{
								name: "图文资讯",
								path: "/twzx"
							},
							{
								name: "视频资讯",
								path: "/spzx"
							},
							// {
							// 	name: "直播回放",
							// 	path: "/zbhf"
							// },
						]
					},
					{
						isList: false,
						name: "赛事",
						path: "/ss"
					},
					{
						isList: true,
						name: "双向赋能",
						list: [{
								name: "研习社",
								path: "/yxs"
							},
							{
								name: "新经略",
								path: "/xjl"
							},
						]
					},
					{
						isList: false,
						name: "供需平台",
						path: "/gxpt"
					},
					{
						isList: false,
						name: "客户案例",
						path: "/khal"
					},
				], //顶部列表
				footList: [{
						name: "展览",
						list: [{
								name: "我要参展",
								path: "/wycz"
							},
							{
								name: "展商指南",
								path: "/zszn"
							},
							{
								name: "展商名录",
								path: "/zsml"
							},
							// {
							// 	name: "活动专区",
							// 	path: "/hdzq"
							// },
							{
								name: "历届展览",
								path: "/ljzl"
							}
						]
					},
					{
						name: "论坛",
						list: [{
							name: "往届论坛",
							path: "/wjlt"
						}, {
							name: "往届嘉宾",
							path: "/wjjb"
						}, ]
					},
					{
						name: "资讯",
						list: [{
								name: "图文资讯",
								path: "/twzx"
							}, {
								name: "视频资讯",
								path: "/spzx"
							},
							// {
							// 	name: "直播回放",
							// 	path: "/zbhf"
							// },
						]
					},
					{
						name: "赋能",
						list: [{
								name: "赛事",
								path: "/ss"
							},
							{
								name: "研习社",
								path: "/yxs"
							},
							{
								name: "新经略",
								path: "/xjl"
							},

						]
					},
					{
						name: "关于我们",
						list: [{
								name: "关于我们",
								path: "/gywm"
							},
							{
								name: "联系我们",
								path: "/lxwm"
							},
							// {
							// 	name: "赞助商名录",
							// 	path: "/zzsml"
							// },

						]
					},
				], //底部列表
			}
		},
		mounted() {
			this.path = this.$route.path;
			this.isMobile = this.$isMobile()
			this.$router.beforeEach((to, from, next) => {
				this.drawer = false
				next()
			})
		},
		watch: {
			$route(to) {
				this.path = to.path
			}
		},
		methods: {
			// 打开菜单
			openMenu(index, type) {
				var content = type == 1 ? document.getElementById("content" + index) : document.getElementById("fcontent" +
					index);
				if (index == 8) {
					content.style.opacity = content.offsetHeight === 30 * 3 ? 0 : 1
					content.style.marginTop = content.offsetHeight === 30 * 3 ? 0 : 10 + 'px'
					content.style.height = content.offsetHeight === 30 * 3 ? 0 + 'px' : 30 * 3 + 'px'
				} else {
					content.style.opacity = content.offsetHeight === 30 * this.tabList[index].list.length ? 0 : 1
					content.style.marginTop = content.offsetHeight === 30 * this.tabList[index].list.length ? 0 : 10 + 'px'
					content.style.height = content.offsetHeight === 30 * this.tabList[index].list.length ? 0 + 'px' : 30 *
						this
						.tabList[index].list.length + 'px'
				}
			}
		}
	}
</script>

<style scoped>
	/deep/.el-drawer__header {
		padding: 0;
		margin: 0;
	}

	/deep/.el-collapse-item__content {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	/deep/.el-collapse-item__header {
		background-color: #E7E8E9;
		border-color: #333;
	}

	/deep/.el-collapse-item__wrap {
		background-color: #E7E8E9;
	}
</style>
<style lang="less">
	.popper {
		background: #F7F8FA !important;
	}

	// /deep/.el-popover{
	// 	background: red;
	// }
	body {
		margin: 0;
	}

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		list-style: none;
	}

	a {
		text-decoration: none;
	}

	.jianju_foot {
		width: 100%;
		height: 7.5rem;
	}

	.jianju {
		width: 100%;
		// height: 40px;
		margin-top: 5rem;
	}

	.jianju_phone {
		width: 100%;
		height: 40px;
	}

	.flexcol {
		display: flex;
		align-items: center;
		flex-direction: column;
	}


	#app {
		// font-family: Avenir, Helvetica, Arial, sans-serif;
		// -webkit-font-smoothing: antialiased;
		// -moz-osx-font-smoothing: grayscale;
		// text-align: center;
		// color: #2c3e50;
	}

	p {
		margin: 0;
		padding: 0;
	}

	.logo {
		width: 9.5rem;
		height: 2rem;
	}

	nav {
		width: 100%;
		height: 5rem;
		background: #FFFFFF;
		display: flex;
		align-items: center;
		// justify-content: center;
		position: fixed;
		padding: 0 20rem;
		box-sizing: border-box;
		top: 0;
		z-index: 1000;
		border-bottom: 1px solid #E7E8E9;

		.tabs {
			margin-left: 19.5rem;
			display: flex;
			align-items: center;

			.line {
				width: 1px;
				height: 3rem;
				background: #16202B;
				margin-right: 1rem;
				margin-left: 1rem;
			}

			.menu_item {
				width: 6.5rem;
				text-align: center;
				// display: flex;
				// align-items: center;
			}

			.menu_top {
				// width: 6.5rem;
				height: 5rem;
				position: relative;

				// padding: 1.25rem 0;
				// box-sizing: border-box;
				// display: flex;
				// align-items: center;
				&:hover {
					.menu_child {
						display: block;
						// opacity: 1;
					}
				}
			}

			.menu_child {
				width: 6.5rem;
				background-color: #FFFFFF;
				// z-index: 1001;
				// position: absolute;
				display: flex;
				flex-direction: column;
				top: 10rem;
				text-align: center;
				display: none;
				// opacity: 0;
				transition: all linear .5s;

				.child_item {
					padding: 0.5rem;
					box-sizing: border-box;

					a {
						font-size: 0.875rem;
					}
				}
			}

			.bottom_menu {
				width: 6.5rem;
				height: 5rem;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 0.5rem;
					height: 0.5rem;
					margin-left: 0.5rem;
				}
			}
		}

		a {
			// width: 6.5rem;
			// text-align: center;
			font-size: 1rem;
			color: #333333;
			text-decoration: none;
			// margin-right: 3rem;

			&.router-link-exact-active {
				font-size: 1rem;
				color: #F40009;
				// font-size: 1.875rem;
				font-weight: 800;
			}
		}
	}

	.phone {
		width: 100%;
		height: 40px;
		background: #F6F6F6;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 15px;
		box-sizing: border-box;
		position: fixed;
		top: 0;
		z-index: 3001;

		.logo_img {
			width: 71px;
			height: 15px;
		}

		.phone_menu {
			width: 16px;
			height: 16px;
		}
	}

	.foot {
		width: 100%;
		// height: 32rem;
		background: #E7E8E9;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		box-sizing: border-box;

		// padding-bottom: 4rem;
		a {
			font-size: 0.875rem;
			color: #444C55;
			text-decoration: none;
			margin-bottom: 1rem;
		}

		.foot_main {
			padding: 2.5rem 20rem;
			box-sizing: border-box;
			display: flex;

			.foot_left {
				width: 26.875rem;
				border-right: 1px solid #D0D2D4;

				.lianxi {
					display: flex;
					align-items: center;
					margin-top: 3rem;
					margin-bottom: 1.5rem;

					div {
						margin-right: 2.3125rem;

						:first-child {
							font-size: 1rem;
							font-weight: 600;
							color: #16202B;
						}

						:last-child {
							font-size: 1rem;
							font-weight: 600;
							color: #F40009;
							margin-top: 3px;
						}
					}
				}

				.address {
					margin-bottom: 1.5rem;

					:first-child {
						font-size: 1rem;
						font-weight: 600;
						color: #16202B;
					}

					:last-child {
						font-size: 0.875rem;
						color: #444C55;
						margin-top: 7px;
					}
				}

				.qrcode {
					font-size: 0.875rem;
					color: #16202B;

					img {
						width: 6rem;
						height: 6rem;
						margin-bottom: 0.5rem;
					}
				}
			}
		}

		.beian {
			width: 100%;
			height: 4rem;
			padding-left: 20rem;
			border-top: 1px solid #D0D2D4;
			font-size: 0.875rem;
			line-height: 4rem;
			color: #16202B;
			box-sizing: border-box;
		}

		.daohang {
			display: flex;

		}

		.daohang_item {
			display: flex;
			flex-direction: column;

			p {
				font-size: 1rem;
				font-weight: 600;
				color: #16202B;
				margin-bottom: 1.6875rem;
			}
		}

		.first_daohang {
			margin-left: 5rem;
		}
	}

	.phone_ul {
		width: 100%;
		padding: 0 15px;
		box-sizing: border-box;

		li {
			padding: 10px 0;
			border-bottom: 1px solid #D8D8D8;
			box-sizing: border-box;
			font-size: 14px;

			a {
				font-weight: 700;
				color: #333333;
				line-height: 20px;
				text-decoration: none;
			}
		}
	}

	.foot_phone {
		width: 100%;
		// height: 32rem;
		background: #FFFFFF;
		box-sizing: border-box;

		.logo {
			width: 71px;
			height: 15px;
		}

		.foot_left {
			width: 100%;
			padding-left: 12px;
			box-sizing: border-box;

			.lianxi {
				// display: flex;
				// align-items: center;

				div {
					font-size: 12px;
					font-weight: 400;
					color: #666666;
					margin-top: 8px;
				}
			}

			.address {
				margin-bottom: 1.5rem;

				:first-child {
					font-size: 1rem;
					font-weight: 600;
					color: #16202B;
				}

				:last-child {
					font-size: 0.875rem;
					color: #444C55;
					margin-top: 7px;
				}
			}

			.qrcode {
				font-size: 0.875rem;
				color: #16202B;

				img {
					width: 100px;
					height: 100px;
					margin-bottom: 0.5rem;
				}
			}
		}
	}

	.foot_mo_main {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-left: 14px;
		box-sizing: border-box;

		a {
			font-size: 12px;
			font-weight: 400;
			color: #666666;
			line-height: 17px;
			text-decoration: none;
			margin-bottom: 5px;
		}
	}

	.foot_texts {
		background-color: #fff;
		padding: 10px;
		box-sizing: border-box;
		text-align: center;

		a {
			color: #444C55;
		}
	}
</style>